import React, { useState } from "react";
import "./CommonStyles.css";
import Navbar from "../components/Navbar";

const NewsBlog = () => {
  const [isReadMore, setIsReadMore] = useState(false);

  const handleClick = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <>
      <Navbar />
      <main className="gd-news--container">
        <h2 className="gd-news--title">
          Exciting Events at GD Goenka School Hyderabad! 🎉
        </h2>

        <section className="gd-news--section">

        <div className="gd-news--event">
            <h3 className="gd-news--event-title">
            Exploring Nature’s Wonders at Mahaveer Vanasthali Park 🌿🌞
            </h3>
            <p>
        <strong>Hyderabad, January 7, 2025</strong> — Today marked a memorable
        journey into the heart of nature at the serene Mahaveer Vanasthali
        Park. Families, nature enthusiasts, and curious minds came together for
        a rejuvenating nature walk, uncovering the park’s vibrant ecosystem and
        its timeless beauty.
      </p>
      <p>
        The experience was more than just a walk; it was an exploration of life
        thriving in its most natural form. From the soft rustling of leaves to
        the melodious chirping of birds, every moment brought participants
        closer to the wonders of nature.
      </p>
      <p>
        Children, with their boundless energy and curiosity, delighted in
        identifying various plants and flowers, learning about their unique
        characteristics. Adults found the experience equally enriching,
        reflecting on the vital role such green spaces play in maintaining
        ecological balance and providing a much-needed escape from the urban
        rush.
      </p>
      <p>
        The event reinforced the importance of conservation and sustainable
        living. It inspired everyone to cherish and protect these green havens,
        ensuring they remain a source of peace and learning for generations to
        come.
      </p>
      <p>
        Mahaveer Vanasthali Park is a reminder of how nature can teach us
        lessons in harmony, resilience, and interconnectedness. It truly stands
        as the perfect outdoor classroom.
      </p>
      <p>
        Let’s pledge to nurture and celebrate these treasures of the natural
        world! 🌱
      </p>
      <div>
        <strong>#NatureWalk #MahaveerVanasthaliPark #ExploreNature #GreenSpaces
        #ReconnectWithNature #PeacefulMoments #EcoExploration #NatureLovers
        #SustainableLiving #NatureIsTheBestTeacher</strong>
      </div>
      <a
        href="https://www.facebook.com/shiva.ganga.9237"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <button
          className="readbtn"
        >
          Visit Our Facebook Page
        </button>
      </a>
          </div>

          <div className="gd-news--event">
            <h3>The Path to Educational Success</h3>
      <p>The path to Educational success, Building strong foundations for future Generations. G.D Goenka School hyd L.B Nagar announce that Advaith, Student of Grade X has brought laurels to our Institution by excelling in the prestigious *Āryabhaṭa Ganit Challenge – 2024, organized by the Central Board of Secondary Education (CBSE)* under circular no. Acad-68/2024.
      Let us all celebrate this proud moment and be inspired by his exemplary achievement!</p>
      <div>
        <strong>#bestschoolnearme #lbnagar #gdgoenkaschool #hyderabad #mathschallenge        </strong>
      </div>
      <a
        href="https://www.facebook.com/shiva.ganga.9237"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <button
          className="readbtn"
        >
          Visit Our Facebook Page
        </button>
      </a>
          </div>

        <div className="gd-news--event">
            <h3 className="gd-news--event-title">
            GD Goenka’s Annual Day Celebrations: A Two-Day Extravaganza(December 14-15, 2024)
            </h3>
            <div className="gd-newsimg">
              <img src="./images/gallery_1.jpg" alt="newsblog3" />
            </div>
            <p>
            GD Goenka School, Hyderabad, celebrated its Annual Day with a spectacular two-day event themed "Celebrating Growth, Talent, and Togetherness." The festivities began on December 14, 2024, with a grand inaugural ceremony graced by Chief Guest Dr. Shalini Rao, followed by soulful performances by the school choir and cultural showcases such as classical dances, a musical medley, and a thought-provoking drama on "Unity in Diversity."


              <hr />
              The Prize Distribution Ceremony on Day 1 honored students for their achievements, and the day concluded with a vibrant group performance symbolizing unity.
              <hr />
              Day 2 highlighted creativity and innovation,  a contemporary dance fusion, and a talent showcase of storytelling, music, and poetry. The grand finale by senior students expressed gratitude to mentors and parents, leaving everyone inspired.

        

              <span
                id="dots"
                style={{ display: isReadMore ? "none" : "inline" }}>
                ...
              </span>
              <span
                id="more"
                style={{ display: isReadMore ? "inline" : "none" }}>
                

                <hr />
                The Principal, in her closing address, praised the collective efforts of students, faculty, and parents, making the Annual Day a grand success and reinforcing the school’s commitment to holistic education.
               

                </span>
            </p>
            <button className="readbtn" onClick={handleClick}>
              {isReadMore ? "Read less" : "Read more"}
            </button>
          </div>

        <div className="gd-news--event">
            <h3 className="gd-news--event-title">
            🎨 Messy Art Day at GD Goenka School: A Celebration of Creativity and Joy! 🎨
            </h3>
            <div className="gd-newsimg">
              <img src="./images/newsblogimg11.jpeg" alt="newsblog3" />
            </div>
            <p>
            At GD Goenka School, we believe that every masterpiece begins with a single splash of color and a whole lot of imagination. On Messy Art Day, our young Picassos from Nursery to Grade II discovered the magic of creativity through hands-on artistic exploration. It was a day filled with vibrant colors, laughter, and boundless inspiration!


              <hr />
              The Theme: "A Masterpiece Cannot Be Created Without a Messy Start"
              <hr />
              The theme of the day encouraged our little artists to embrace imperfection and see beauty in the process. From messy hands to colorful faces, every moment was a reminder that creativity knows no bounds. It wasn’t just about the final artwork; it was about the journey of experimenting, playing, and learning.

                <hr />
                A Day of Creative Exploration

              <span
                id="dots"
                style={{ display: isReadMore ? "none" : "inline" }}>
                ...
              </span>
              <span
                id="more"
                style={{ display: isReadMore ? "inline" : "none" }}>
                

                <hr />
                The school ground was transformed into an open art studio, buzzing with energy and excitement. Children eagerly participated in activities like:
                <hr />
                Finger Painting: Tiny hands brought big ideas to life with swirls and splashes of paint.


                <hr />
                Sponge Stamping: Unique shapes and patterns emerged as students stamped their creativity across the canvas.
                <hr />
                Group Murals: Teamwork took center stage as children collaborated to create stunning group art pieces, celebrating unity in creativity.
                <hr />
                Each activity was designed to nurture their artistic instincts while teaching important values like teamwork, patience, and self-expression.

                <hr />
                The Joy of Learning Through Art

                <hr />
                Messy Art Day wasn’t just about art—it was about fun learning. Through this hands-on experience, children developed fine motor skills, boosted their confidence, and unleashed their imaginations. It was a day where classrooms turned into playgrounds of creativity, and learning felt like pure joy.
                <hr />
                A Colorful Outcome
                <hr />
                By the end of the day, the results were spectacular! The children’s artwork adorned the school grounds, transforming them into a gallery of vibrant murals and masterpieces. These creations weren’t just beautiful—they were filled with stories, laughter, and the shared memories of a magical day.

                <hr />
                Moments That Last Forever

                Messy Art Day wasn’t just an event; it was a celebration of the creative spirit that lies within every child. At GD Goenka School, we believe in fostering such moments where children can freely explore, learn, and grow.
                <hr />
                Here’s to more days of colorful mess, creative success, and the joy of learning!
                <hr />
                #MessyArtDay #CreativeLearning #LittleArtists #GDGoenkaPride #BestSchoolForKids #UnleashingImagination #GDGoenkaHyderabad

                </span>
            </p>
            <button className="readbtn" onClick={handleClick}>
              {isReadMore ? "Read less" : "Read more"}
            </button>
          </div>

        <div className="gd-news--event">
            <h3 className="gd-news--event-title">
            Admissions Open for 2025-26: Limited Seats Available at GD Goenka School!
            </h3>
            <div className="gd-newsimg">
              <img src="./images/newsblogimg10.jpg" alt="newsblog3" />
            </div>
            <p>
            Join Hyderabad’s Most Prestigious School – GD Goenka, where excellence meets international standards!

              <hr />
              ✨ Why Choose GD Goenka?
              <hr />
                Unmatched Academic Excellence: Our students consistently achieve top results in academics, preparing them for global success.

                <hr />
                Sports Champions: Last year, we celebrated multiple victories in state and national-level sports competitions.
              <span
                id="dots"
                style={{ display: isReadMore ? "none" : "inline" }}>
                ...
              </span>
              <span
                id="more"
                style={{ display: isReadMore ? "inline" : "none" }}>
                

                <hr />
                Cultural Brilliance: From music and dance to drama and debates, our students shine on every stage.
                <hr />
                World-Class Infrastructure: International-standard classrooms, science and robotics labs, AI labs, and an advanced library foster cutting-edge learning.

                <hr />
                Safety First: With 24/7 CCTV surveillance and dedicated staff, we ensure a safe and nurturing environment for your child.
                <hr />
                🎯 Seats are filling fast! Give your child the gift of holistic education.
                <hr />
                📅 Enroll Today – Admissions are open for a limited time. Don’t miss the opportunity to be part of Hyderabad’s leading educational institution.
                <hr />
                visit us at  www.gdgoenkahyd.com
                </span>
            </p>
            <button className="readbtn" onClick={handleClick}>
              {isReadMore ? "Read less" : "Read more"}
            </button>
          </div>


          <div className="gd-news--event">
            <h3 className="gd-news--event-title">
              🌟 Children’s Day Celebrations at GD Goenka School 🌟
            </h3>
            <div className="gd-newsimg">
              <img src="./images/newsblogimg9.jpeg" alt="newsblog3" />
            </div>
            <p>
              Celebrating the Joy of Childhood
              <hr />
              Children’s Day 2024 at GD Goenka School was a celebration to
              remember, filled with excitement, laughter, and endless fun. Our
              little stars truly experienced the magic of the day, making it a
              memorable event for everyone involved.
              <hr />A Magical Start The festivities kicked off with a unique Sun
              Gazing session, where the children marveled at the beauty of
              nature and learned about the wonders of the universe. This
              tranquil and awe-inspiring activity set the perfect tone for the
              rest of the day.
              <span
                id="dots"
                style={{ display: isReadMore ? "none" : "inline" }}>
                ...
              </span>
              <span
                id="more"
                style={{ display: isReadMore ? "inline" : "none" }}>
                <hr />
                Surprise Visitors – Mickey and Minnie! As the day progressed,
                the excitement soared with a surprise visit from everyone’s
                favorite duo – Mickey and Minnie Mouse! The children’s faces lit
                up with joy as they greeted their special guests. Mickey and
                Minnie brought the magic alive, sharing hugs, laughter, and
                unforgettable moments with the kids.
                <hr />
                Fun, Games, and Dancing! The celebrations continued with an
                array of fun-filled activities. From engaging games to an
                energetic dance session, the children couldn’t stop smiling.
                Their laughter echoed across the school as they enjoyed every
                bit of the celebration.
                <hr />
                Gifts Galore No Children’s Day is complete without gifts! Our
                young ones were thrilled to receive thoughtful presents that
                made the day even more special. Seeing their delight was the
                highlight of the event for all the teachers and staff.
                <hr />
                A Day to Cherish Forever The entire school was brimming with joy
                and positive energy. The children, teachers, and staff all came
                together to celebrate the essence of childhood – innocence,
                curiosity, and boundless happiness.
                <hr />
                At GD Goenka School, we believe in creating unforgettable
                experiences for our students, and this Children’s Day was no
                exception. Here’s to many more joyous celebrations with our
                proud Goenkans!
                <hr />
                #ChildrensDay2024 #GDGoenkaFun #HappyKids #GDGoenkaExcellence
                #BestSchoolNearMe #ProudGoenkans #ShivagangaGroup #Hyderabad
                #RockTown #ChildhoodMemories #SchoolCelebrations #JoyfulLearning
                #UnforgettableMoments
              </span>
            </p>
            <button className="readbtn" onClick={handleClick}>
              {isReadMore ? "Read less" : "Read more"}
            </button>
          </div>

          <div className="gd-news--event">
            <h3 className="gd-news--event-title">
              GD Goenka Hyderabad's Inspiring Academic Fair: Showcasing Student
              Innovation and Ingenuity
            </h3>
            <div className="gd-newsimg">
              <img src="./images/newsblogimg8.jpeg" alt="newsblog3" />
            </div>
            <p>
              On November 9th, GD Goenka Hyderabad's campus came alive with
              creativity as students displayed their projects at the Academic
              Fair, showcasing the school’s commitment to hands-on learning.
              Highlights of the Fair
              <hr />
              1. Magnet-Powered Energy: Students demonstrated clean, renewable
              energy generation through magnets, impressing visitors with
              potential real-world applications.
              <hr />
              2. Sustainable Agriculture: Innovative farming techniques like
              hydroponics and vertical gardening highlighted the students'
              forward-thinking approach to feeding future generations.
              <hr />
              3. Astronomy Displays: From moon phases to black holes, students
              offered an interactive, awe-inspiring look into the cosmos.{" "}
              <span
                id="dots"
                style={{ display: isReadMore ? "none" : "inline" }}>
                ...
              </span>
              <span
                id="more"
                style={{ display: isReadMore ? "inline" : "none" }}>
                <hr />
                4. Science Experiments: Live demonstrations in chemistry,
                physics, and biology made science accessible and fun.
                <hr />
                The Academic Fair was a testament to GD Goenka’s dedication to
                nurturing curiosity and leadership in students. Looking for a
                school that fosters growth beyond the classroom? GD Goenka
                Hyderabad could be the perfect choice for your child’s future.
                Enroll today!
              </span>
            </p>
            <button className="readbtn" onClick={handleClick}>
              {isReadMore ? "Read less" : "Read more"}
            </button>
          </div>

          <div className="gd-news--event">
            <h3 className="gd-news--event-title">
              Diwali Festive Celebrations at GD Goenka School, Hyderabad: A Day
              of Joy, Music, and Culture
            </h3>
            <div className="gd-newsimg">
              <img src="./images/newsblogimg7.jpeg" alt="newsblog3" />
            </div>
            <p>
              Diwali, the festival of lights, is one of the most anticipated and
              cherished festivals in India. This year, GD Goenka School,
              Hyderabad, pulled out all the stops to make the Diwali celebration
              a memorable event, showcasing a beautiful blend of tradition,
              culture, and festive joy. The day was packed with colorful
              decorations, energetic performances, and an atmosphere filled with
              excitement and unity among students, teachers, and staff.
              <hr />
              The Vibrant Atmosphere and Festive Spirit As the students and
              staff walked into the campus, they were greeted by vibrant
              decorations and colorful rangoli art crafted by students with
              skill and creativity. Traditional earthen lamps (diyas) and
              flowers adorned the school corridors, adding to the festive{" "}
              <span
                id="dots"
                style={{ display: isReadMore ? "none" : "inline" }}>
                ...
              </span>
              <span
                id="more"
                style={{ display: isReadMore ? "inline" : "none" }}>
                atmosphere. The halls echoed with joyful chatter as everyone
                looked forward to a day of celebration, learning, and, above
                all, unity.
                <hr />
                Cultural Dance Performances: Celebrating India’s Diversity
                <hr />
                One of the most captivating highlights of the day was the series
                of cultural dance performances by students. From Bharatanatyam
                and Kathak to folk dances like Garba and Dandiya, students from
                various grades took to the stage, dressed in traditional attire
                that reflected the diversity of Indian culture. Each performance
                was thoughtfully choreographed, with students enthusiastically
                embodying the spirit of Diwali through every movement and
                expression. The vibrant colors, music, and energy lit up the
                stage and won applause from the entire audience. Music That
                Brings Festivity to Life
                <hr />
                Music is at the heart of any celebration, and the Diwali event
                at GD Goenka was no exception. The school choir performed
                soulful renditions of classical and devotional songs dedicated
                to the goddess Lakshmi, symbolizing prosperity, health, and
                happiness. Students also played traditional instruments like the
                tabla and sitar, adding a touch of classical charm to the
                celebrations. The fusion of traditional and contemporary music
                was well received by the audience, who were seen tapping their
                feet and clapping along with the beats.
                <hr />
                Diyas of Hope: A Symbolic Gesture for a Brighter Future
                <hr />
                In a touching ceremony, students and staff came together to
                light diyas as a symbol of hope, positivity, and goodwill. This
                beautiful ritual was accompanied by students sharing heartfelt
                messages about the importance of spreading kindness, empathy,
                and joy. The school emphasized the deeper message of Diwali –
                the victory of light over darkness, knowledge over ignorance,
                and good over evil.
                <hr />
                A Glimpse of Togetherness and Community Spirit
                <hr />
                The Diwali celebration at GD Goenka was not just about music and
                dance – it was also a day dedicated to fostering community
                spirit and togetherness. Teachers, students, and parents joined
                hands in making the event successful, whether through organizing
                the performances, decorating the campus, or participating in the
                celebrations. Everyone felt a sense of belonging and pride in
                celebrating this special day together.
                <hr />
                Wrapping Up a Memorable Celebration
                <hr />
                The Diwali event concluded with a delightful feast featuring
                traditional Indian sweets and snacks, leaving everyone with fond
                memories of the day. The celebrations at GD Goenka School were a
                reminder of the importance of cherishing our cultural heritage
                and spreading joy and positivity in our lives.
                <hr />
                This Diwali event at GD Goenka School, Hyderabad, was truly a
                day to remember, filled with laughter, learning, and vibrant
                celebration. It was a wonderful opportunity for students to
                connect with their roots and for the school community to come
                together in unity and festivity. May the light of Diwali
                continue to shine brightly, bringing hope, prosperity, and
                happiness to all!
              </span>
            </p>
            <button className="readbtn" onClick={handleClick}>
              {isReadMore ? "Read less" : "Read more"}
            </button>
          </div>

          <div className="gd-news--event">
            <h3 className="gd-news--event-title">
              Successful Term End PTM for Grades III to X at GD Goenka School,
              Hyderabad!
            </h3>
            <div className="gd-newsimg">
              <img src="./images/newsblogimg6.jpeg" alt="newsblog3" />
            </div>
            <p>
              At GD Goenka School, Hyderabad, we believe that open communication
              between parents and teachers is essential for each student’s
              success. We are thrilled to announce the success of our recent
              Term End Parent-Teacher Meeting (PTM) for Grades III to X! It was
              wonderful to see so many parents joining us to discuss their
              child’s progress, strengths, and areas for growth.
              <hr />
              The PTM provided a platform for in-depth conversations, allowing
              parents and teachers to work together to support each student’s
              unique learning journey. We are deeply grateful for the active
              involvement of our parents{" "}
              <span
                id="dots"
                style={{ display: isReadMore ? "none" : "inline" }}>
                ...
              </span>
              <span
                id="more"
                style={{ display: isReadMore ? "inline" : "none" }}>
                , whose encouragement and collaboration strengthen our school
                community. This partnership between home and school is the
                foundation on which we build a nurturing and empowering
                environment for our students. A heartfelt thank you to all who
                participated and contributed to making this PTM a valuable
                experience. We look forward to continuing this journey with you,
                fostering learning and growth as we help each child reach their
                fullest potential. Here’s to many more milestones ahead!
                <hr />A heartfelt thank you to all who participated and
                contributed to making this PTM a valuable experience. We look
                forward to continuing this journey with you, fostering learning
                and growth as we help each child reach their fullest potential.
                Here’s to many more milestones ahead!
              </span>
            </p>
            <button className="readbtn" onClick={handleClick}>
              {isReadMore ? "Read less" : "Read more"}
            </button>
          </div>

          <div className="gd-news--event">
            <h3 className="gd-news--event-title">
              GD Goenka Hyderabad Students Explore the World of Animal
              Husbandry!
            </h3>
            <div className="gd-newsimg">
              <img src="./images/newsblogimg5.jpeg" alt="newsblog3" />
            </div>
            <p>
              At GD Goenka School, Hyderabad, we believe in providing our
              students with hands-on learning experiences that go beyond the
              classroom. This week, our students had the incredible opportunity
              to dive into the fascinating world of animal husbandry! As part of
              our curriculum, we introduced our learners to this important
              agricultural practice, helping them understand the significance of
              raising and caring for animals in sustainable ways.
              <hr />
              During this engaging session, students learned{" "}
              <span
                id="dots"
                style={{ display: isReadMore ? "none" : "inline" }}>
                ...
              </span>
              <span
                id="more"
                style={{ display: isReadMore ? "inline" : "none" }}>
                about the various aspects of animal husbandry, from the care and
                feeding of livestock to the importance of maintaining animal
                health and hygiene. They explored how farmers raise animals such
                as cows, sheep, goats, and poultry, and how these animals
                contribute to food production and rural economies. The
                interactive experience also allowed them to observe how proper
                animal care leads to ethical practices in farming.
                <hr />
                Through this practical lesson, students gained valuable insights
                into the role animal husbandry plays in our society. They
                understood how sustainability and responsible farming practices
                help meet the growing demand for food, and how it all connects
                to the larger picture of environmental and agricultural health.
                <hr />
                The excitement and curiosity among our students were evident as
                they asked thoughtful questions and actively participated in the
                session. By encouraging students to explore topics like animal
                husbandry, we aim to foster a deeper appreciation for
                agriculture, nature, and sustainability.
                <hr />
                Stay tuned for more such enriching and hands-on learning
                experiences at GD Goenka School, Hyderabad!
                <hr />
                Hashtags: #AnimalHusbandry #HandsOnLearning
                #SustainabilityEducation #GDGoenkaSchool #AgricultureAwareness
                #gdgoenkahyderabad #HolisticEducation #PracticalLearning
                #STEMLearning #FutureFarmers #bestschoolnearme #HyderabadSchools
              </span>
            </p>
            <button className="readbtn" onClick={handleClick}>
              {isReadMore ? "Read less" : "Read more"}
            </button>
          </div>

          <div className="gd-news--event">
            <h3 className="gd-news--event-title">
              Successful PTM for Nursery to Grade II at GD Goenka School,
              Hyderabad!
            </h3>
            <div className="gd-newsimg">
              <img src="./images/newsblogimg4.jpeg" alt="newsblog3" />
            </div>
            <p>
              At GD Goenka School, Hyderabad, we believe that the foundation of
              a child's education begins with a strong partnership between
              parents and teachers. Today, we were thrilled to host a successful
              Parent-Teacher Meeting (PTM) for our Nursery to Grade II students.
              This interactive session provided a valuable opportunity for
              teachers and parents to discuss the academic progress and overall
              development of our young learners.
              <hr />
              The PTM allowed parents to gain deeper insights into their child's
              learning journey, classroom activities, and future goals. It was
              heartwarming to see such active
              <span
                id="dots"
                style={{ display: isReadMore ? "none" : "inline" }}>
                ...
              </span>
              <span
                id="more"
                style={{ display: isReadMore ? "inline" : "none" }}>
                {" "}
                participation from parents, as their engagement plays a crucial
                role in shaping the educational experience for their children.
                Together, we can ensure the best environment for growth,
                development, and success.
                <hr />
                We would like to extend our heartfelt thanks to all the parents
                who attended and contributed to making this PTM a productive and
                meaningful event. We look forward to continuing this partnership
                as we nurture our young learners in both academics and
                character-building.
                <hr />
                Stay tuned for more exciting updates and events from GD Goenka
                School, Hyderabad!
                <hr />
                #PTMSuccess #SchoolParentPartnership #GDGoenkaSchool
                #HolisticLearning
              </span>
            </p>
            <button className="readbtn" onClick={handleClick}>
              {isReadMore ? "Read less" : "Read more"}
            </button>
          </div>

          <div className="gd-news--event">
            <h3 className="gd-news--event-title">
              Exploring Agriculture and Sustainability: A Field Trip to ONE DAY
              KISSAN
            </h3>

            <p>
              <strong>Date:</strong> 19th October 2024
            </p>
            <div className="gd-newsimg">
              <img src="./images/newsblog3.jpg" alt="newsblog3" />
            </div>
            <p>
              At GD Goenka School, Hyderabad, we believe that education extends
              beyond the classroom, giving students real-world experiences that
              ignite curiosity and foster a deeper understanding of the world
              around them. In line with this vision, our students from Grades VI
              to X recently embarked on an exciting educational field trip to
              ONE DAY KISSAN, a hub for learning about
              agriculture,sustainability, and farm-to-table practices. The field
              trip was designed to provide our young learners with hands-on
              experience in agriculture, helping{" "}
              <span
                id="dots"
                style={{ display: isReadMore ? "none" : "inline" }}>
                ...
              </span>
              <span
                id="more"
                style={{ display: isReadMore ? "inline" : "none" }}>
                them understand how food is grown and the crucial role that
                farming plays in our daily lives. From interactive sessions on
                sustainable farming techniques to fun, engaging activities in
                the fields, our students had a unique opportunity to connect
                with nature and grasp the importance of eco-friendly practices.
                During their time at ONE DAY KISSAN, students participated in a
                variety of activities, including:
                <ul>
                  <li>
                    Planting and Harvesting: Understanding the life cycle of
                    crops and the care required to cultivate them.
                  </li>
                  <li>
                    Sustainable Farming Practices: Learning about organic
                    farming, water conservation, and the significance of
                    reducing chemical usage to protect the environment.
                  </li>
                  <li>
                    Farm-to-Table Lessons: Exploring how food moves from the
                    farm to our plates and why it's important to support local
                    farmers and sustainable agriculture.
                  </li>
                  <li>
                    Interactive Workshops: Engaging in discussions about the
                    future of farming, technology's role in agriculture, and how
                    youth can contribute to a sustainable planet.
                  </li>
                </ul>
                <p>
                  By the end of the day, students returned with not only dirt
                  under their fingernails but also a newfound appreciation for
                  the hard work and dedication that goes into farming. This
                  enriching experience has inspired them to be more mindful of
                  where their food comes from and how their choices can impact
                  the environment.
                </p>
                <p>
                  At GD Goenka, we are proud to nurture responsible citizens who
                  are well-versed in the importance of sustainability and
                  environmental stewardship. The trip to ONE DAY KISSAN was a
                  testament to our commitment to holistic education, blending
                  academic learning with practical life skills.
                </p>
                <p>
                  Stay tuned for more exciting learning adventures as we
                  continue to plant the seeds of knowledge and growth in our
                  students!
                </p>
              </span>
            </p>
            <button className="readbtn" onClick={handleClick}>
              {isReadMore ? "Read less" : "Read more"}
            </button>
          </div>

          <div className="gd-news--event">
            <h3 className="gd-news--event-title">
              Dandiya Night on 28th September 2024 – A Night of Fun, Fashion,
              and Festivities!
            </h3>
            <p>
              Get ready to groove to the beats of Dandiya at GD Goenka School,
              Hyderabad! We are thrilled to announce an exciting Dandiya Night
              on 28th September 2024. This is not just any ordinary Dandiya
              event – it’s a DJ Night filled with fun, fashion, food, and dance.
            </p>
            <ul>
              <li>
                DJ Music: Dance the night away to the electrifying beats of our
                DJ.
              </li>
              <li>
                Food Stalls: Indulge in mouth-watering treats from various food
                stalls offering a variety of delicacies.
              </li>
              <li>
                Fashion Exhibition: Explore trendy collections and designer wear
                at the fashion exhibition.
              </li>
              <li>
                Fashion Show: Parents and kids can showcase their traditional
                attire and stand a chance to win special prizes.
              </li>
            </ul>
            <p>
              <strong>Date:</strong> 28th September 2024
              <hr />
              <strong>Venue:</strong> GD Goenka School Ground, Hyderabad
              <hr />
              <strong>Time:</strong> 5 PM to 10 PM
            </p>
            <p>
              Dress in your best traditional attire – ghagra for females and
              sherwani for males is compulsory. Don’t miss this dazzling night
              of dance, food, and entertainment!
            </p>
            <p>
              <strong>
                Free entry for GD Goenka students, ₹199/- for parents, and
                ₹499/- for other guests.
              </strong>
            </p>
            <p>For more details or registrations</p>
            <p>
              ☎️ Contact:{" "}
              <a
                style={{ textDecoration: "none", color: "#D6291E" }}
                href="tel:+91949567143">
                +91 9949567143
              </a>
            </p>
          </div>

          <div className="gd-news--event">
            <h3 className="gd-news--event-title">
              Adventure and Life Skills Camp: October 2-4, 2024 – Vikarabad
              Forest
            </h3>
            <p>
              Calling all GD Goenka students for an unforgettable outdoor
              experience! We are organizing an Adventure and Life Skills Camp
              from October 2nd to 4th, 2024 in the beautiful Vikarabad Forest.
            </p>
            <p>
              This thrilling 3-day camp will give students the opportunity to:
            </p>
            <ul>
              <li>
                Develop essential life skills through exciting adventure
                activities.
              </li>
              <li>
                Learn teamwork, problem-solving, and leadership in a natural
                setting.
              </li>
              <li>
                Participate in nature trails, trekking, camping, and survival
                skills.
              </li>
            </ul>
            <p>
              <strong>Dates:</strong> October 2-4, 2024
              <hr />
              <strong>Location:</strong> Vikarabad Forest
            </p>
            <p>
              This camp is an excellent opportunity for students to connect with
              nature, make new friends, and discover their true potential.
            </p>
            <p>
              <strong>
                This event is conducted by{" "}
                <a
                  href="https://learnorama.in"
                  style={{ textDecoration: "none", color: "#D6291E" }}>
                  Learn Orama Technologies Pvt Ltd.
                </a>
              </strong>
            </p>
            <p>
              For more details or registrations,
              <hr /> visit:{" "}
              <a
                className="linkk-news"
                style={{ textDecoration: "none", color: "#D6291E" }}
                href="https://learnorama.in/#/winterquestcamp"
                target="_blank"
                rel="noopener noreferrer">
                https://learnorama.in/#/winterquestcamp
              </a>
            </p>
            <p>
              ☎️ Contact:{" "}
              <a
                style={{ textDecoration: "none", color: "#D6291E" }}
                href="tel:+919849856143">
                +91 9849856143
              </a>
            </p>
          </div>
        </section>
      </main>
    </>
  );
};

export default NewsBlog;
